export const NAVBAR_LINKS = [
  {
    title: "work",
    path: "#work",
  },
  {
    title: "services",
    path: "#services",
  },
  {
    title: "case studies",
    label: "soon",
  },
  {
    title: "FAQ",
    path: "#faq",
  },
  {
    title: "testimonials",
    path: "#testimonials",
  },
];
